import Parse from "parse";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Root from "./pages/Root";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Analytics } from "@vercel/analytics/react";
import "dayjs/locale/el";
import "react-tooltip/dist/react-tooltip.css";

Parse.setAsyncStorage(AsyncStorage);
Parse.initialize(
  process.env.REACT_APP_BACK4APP_APPLICATION_ID,
  process.env.REACT_APP_BACK4APP_JS_KEY
);
Parse.masterKey = process.env.REACT_APP_MASTER_KEY;
Parse.serverURL = process.env.REACT_APP_BACK4APP_SERVER_URL;

function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
        <Root />
        <Analytics />
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
