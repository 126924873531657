import { dateFormatter } from "../utils/Dates";

export function userSerializer(user) {
  return {
    id: user.id,
    customerId: user.attributes.customerId,
    username: user.attributes.username,
    firstName: user.attributes.name,
    lastName: user.attributes.lastName,
    phone: user.attributes.phone,
    dateOfBirth: dateFormatter(user.attributes.dateOfBirth),
    idDocument: {
      front: user.attributes.idFront?._url,
      back: user.attributes.idBack?._url,
    },
    license: {
      id: user.attributes.licenseNo,
      front: user.attributes.licenseFront?._url,
      back: user.attributes.licenseBack?._url,
      issue: dateFormatter(user.attributes.licenseIssueDate),
      expire: dateFormatter(user.attributes.licenseExpiryDate),
    },
    profilePicture: user.get("profilePicture")?._url,
    emailVerified: user.attributes.emailVerified,
    phoneVerified: user.attributes.phoneVerified,
    isAuthorized: user.attributes.isAuthorized,
    isRejected: user.attributes.isRejected,
    isEnabled: user.attributes.isEnabled,
    isLocked: user.attributes.isLocked,
    isLoggedIn: user.attributes.isLoggedIn,
    referredBy: user.get("referredBy")?.id,
    comment: user.get("comment"),
    createdAt: dateFormatter(user.attributes.createdAt, true),
    updatedAt: dateFormatter(user.attributes.updatedAt, true),
  };
}

export function tokenSerializer(token) {
  return {
    id: token.id,
    user: userSerializer(token.attributes.user),
    token: token.attributes.token,
    type: token.attributes.type,
    isUsed: token.attributes.isUsed,
    expiryDate: dateFormatter(token.attributes.expiryDate, true),
  };
}
