import Header from "../components/Header";
import Footer from "../components/Footer";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "./NotFoundPage";
import ResetPasswordPage from "./reset-password/ResetPasswordPage";
import "../styles/styles.scss";

const Root = () => {
  return (
    <div className="main-app">
      <Header />
      <div className="content">
        <Routes>
          <Route path="/password-reset" element={<ResetPasswordPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Root;
