import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "../../styles/styles.scss";
import Utils from "../../utils/Utils";

const FormInput = ({
  min,
  max,
  type,
  name,
  label,
  value,
  placeholder,
  onChange,
  onBlur,
  errors,
  touched,
  required = false,
  classes = null,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (value) => {
    onChange(name, value);
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  return (
    <div>
      {label && (
        <label className="text-xs text-gray-600 pl-1 font-normal mb-1">
          {label}
        </label>
      )}
      <div className="relative">
        <div className="w-full form-control">
          <input
            name={name}
            value={value}
            min={min ?? null}
            max={max ?? null}
            required={required}
            placeholder={placeholder}
            type={showPassword ? "text" : type}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={() => handleBlur(name, true)}
            className={`${!Utils.isNull(classes) && classes} 
            ${!!(errors && touched) ? "is-invalid" : ""} 
            ${!errors && touched ? "is-valid" : ""} 
            w-full`}
          />
        </div>
        {type === "password" && (
          <span
            className="absolute right-0 top-1/2 -translate-y-1/2 mr-2 cursor-pointer p-[2px]"
            onClick={handlePasswordVisibility}
          >
            <FontAwesomeIcon
              className="text-gray-400"
              icon={showPassword ? faEyeSlash : faEye}
              size="xl"
            />
          </span>
        )}
      </div>
      {errors && touched && (
        <span className="text-sm pl-2 text-red-500">{errors}</span>
      )}
    </div>
  );
};

export default FormInput;
