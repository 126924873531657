import dayjs from "dayjs";
import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import ResetPasswordPageView from "../view/ResetPasswordPageView";
import { tokenSerializer } from "../../../models/serializers";

const ResetPasswordPageState = () => {
  const token = new URLSearchParams(window.location.search).get("token");
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [retrievedToken, setRetrievedToken] = useState(null);
  const [finishStatus, setFinishStatus] = useState(null);

  const checkToken = async () => {
    try {
      const Tokens = Parse.Object.extend("Tokens");
      const query = new Parse.Query(Tokens);
      query.equalTo("token", token);
      const t = await query.first({ useMasterKey: true });

      if (Utils.isNull(t)) {
        setErrors("Invalid token");
        return;
      } else {
        setRetrievedToken(t);
        setUser(t.get("user"));
        const tS = tokenSerializer(t);

        const currentDate = dayjs(new Date().toISOString());
        const tokenExpiryDate = dayjs(
          new Date(t?.get("expiryDate")).toISOString()
        );
        const isExpired = dayjs().isAfter(tokenExpiryDate, currentDate);

        if (tS.isUsed) setErrors("Invalid token");
        if (isExpired) setErrors("Token is expired");
        if (tS.type !== "PASSWORD") setErrors("Invalid token");
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const handlePasswordChangeSubmit = async (values) => {
    setIsLoading(true);
    try {
      const User = new Parse.Object("_User").set("objectId", user.id);
      User.set("password", values.password);
      await User.save(null, { useMasterKey: true }).then(async () => {
        const token = new Parse.Object("Tokens").set(
          "objectId",
          retrievedToken.id
        );
        token.set("isUsed", true);
        await token.save(null, { useMasterKey: true }).then((r) => {
          setIsLoading(false);
          setFinishStatus("success");
        });
      });
    } catch (e) {
      console.error(e.message);
      setFinishStatus("failure");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <>
      {errors ? (
        <div className="text-center">
          <span className="text-red-700">{errors}</span>
        </div>
      ) : retrievedToken ? (
        <ResetPasswordPageView
          isLoading={isLoading}
          finishStatus={finishStatus}
          handlePasswordChangeSubmit={handlePasswordChangeSubmit}
        />
      ) : null}
    </>
  );
};

export default ResetPasswordPageState;
