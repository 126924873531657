import * as Yup from "yup";
import { Form, Formik } from "formik";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";

const ResetPasswordPageView = ({
  isLoading,
  finishStatus,
  handlePasswordChangeSubmit,
}) => {
  const initialValues = {
    password: "",
    repassword: "",
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(30, "Password cannot be more than 30 characters")
      .matches(
        "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\\W)|(?=.*_))^[^ ]+$",
        "Password must contain at least one capital letter, a number, and a special character"
      ),
    repassword: Yup.string()
      .required("Confirm password is required")
      .min(8, "Confirm password must be at least 8 characters")
      .max(30, "Confirm password cannot be more than 30 characters")
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .matches(
        "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\\W)|(?=.*_))^[^ ]+$",
        "Confirm password must contain at least one capital letter, a number, and a special character"
      ),
  });

  return (
    <div className="page-wrapper">
      <div className="page-content with-bg w-full md:w-2/3 mx-auto mt-10">
        <div className="mb-8 text-center">
          <span className="text-2xl">Change your password</span>
        </div>
        {finishStatus ? (
          finishStatus === "success" ? (
            <div className="text-xl text-green-700 text-center">
              <span className="block">
                Your password has been updated successfully!
              </span>
              <span className="block">
                Return back to the Caroo app and login again!
              </span>
            </div>
          ) : (
            <div className="text-xl text-red-700 text-center">
              <span>Updating your password has failed. Please try again.</span>
            </div>
          )
        ) : (
          <div className="change-password-container">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handlePasswordChangeSubmit(values);
              }}
            >
              {(props) => {
                return (
                  <Form
                    className="grid grid-cols-1 gap-2"
                    onSubmit={props.handleSubmit}
                  >
                    <FormInput
                      classes="w-full"
                      type="password"
                      name="password"
                      value={props.values.password}
                      label="Password"
                      placeholder="Password"
                      errors={props.errors.password}
                      touched={props.touched.password}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                    />
                    <FormInput
                      classes="w-full"
                      type="password"
                      name="repassword"
                      label="Confirm Password"
                      value={props.values.repassword}
                      placeholder="Repeat Password"
                      errors={props.errors.repassword}
                      touched={props.touched.repassword}
                      onChange={props.setFieldValue}
                      onBlur={props.setFieldTouched}
                    />
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      classes="bg-blue-500 text-white py-2 mt-4"
                    >
                      Submit
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPageView;
