import Utils from "./Utils";
import dayjs from "dayjs";

export function dateFormatter(date, includeTime = false) {
  if (Utils.isNull(date)) return null;
  const createdAt = new Date(date);
  const year = createdAt.getFullYear();
  const month = addZeroBefore(createdAt.getMonth() + 1);
  const day = addZeroBefore(createdAt.getDate());
  const hour = addZeroBefore(createdAt.getHours());
  const minutes = addZeroBefore(createdAt.getMinutes());

  let formattedDate = day + "/" + month + "/" + year;

  if (includeTime) {
    formattedDate += " - " + hour + ":" + minutes;
  }

  return formattedDate;
}

export function addZeroBefore(n) {
  return (n < 10 ? "0" : "") + n;
}

export function secondsToHoursMinutes(time) {
  let hours = Math.floor(Math.floor(time / 60) / 60);
  let minutes = (Math.floor(time / 60) % 60) + 1;

  return hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
}

export function differenceInYears(date) {
  const day = date.split("/")[0];
  const month = date.split("/")[1] - 1; // -1 because dayjs counts months from 0 and not 1 so Feb would be 1 and not 2
  const year = date.split("/")[2];
  let age = dayjs().set("date", day).set("month", month).set("year", year);
  let today = dayjs(new Date());
  let difference = today.diff(age, "year");

  return difference;
}
