import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const Button = ({
  children,
  type = "button",
  isLoading,
  classes,
  handleClick,
  disabled,
}) => {
  return (
    <button
      type={type}
      className={`${
        isLoading || disabled
          ? "disabled bg-opacity-80 cursor-default"
          : "cursor-pointer"
      } relative hover:bg-opacity-80 rounded transition-all ease-in-out duration-100 ${classes}`}
      onClick={handleClick}
      disabled={isLoading || disabled}
    >
      {children}
      {isLoading && (
        <div className="absolute top-1/2 transform -translate-y-1/2 right-4">
          <FontAwesomeIcon
            className="animate-spin text-gray-100 ml-1"
            icon={faCircleNotch}
          />
        </div>
      )}
    </button>
  );
};

export default Button;
