import React from "react";
import Logo from "../assets/images/caroo_logo-light.svg";
import "./styles/Header.scss";

const Header = () => {
  return (
    <div className="header">
      <div className="wrapper">
        <div className="flex gap-14 items-center">
          <div className="logo">
            <img src={Logo} alt="Caroo-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
